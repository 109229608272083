import React, { createContext, useCallback, useRef, useState } from "react";
import { trigger } from "./event";

export const CartContext = createContext([]);
export const AddCartContext = createContext(item => { });
export const RemoveCartContext = createContext(item => { });

export const addItemCart = (item) => {
    let prev_items = JSON.parse(localStorage.getItem('cart')) || [];
    if (!prev_items.find(x => x.id === item.id && x.color === item.color)) {
        prev_items.push(item);
    }
    setItemsCart(prev_items);
}

export const setItemsCart = (items) => {
    localStorage.setItem('cart', JSON.stringify(items));
    trigger('cartChange');
}

export const removeItemCart = (id, color) => {
    let items = JSON.parse(localStorage.getItem('cart')) || [];
    const newItems = items.filter(
        _item => _item.id !== id && color !== _item.color
    );
    setItemsCart(newItems);
}

export const getItemsCart = () => {
    let items = JSON.parse(localStorage.getItem('cart')) || [];
    return items;
}

export const getItemCart = (id) => {
    let items = JSON.parse(localStorage.getItem('cart')) || [];
    return items.find(x => x.id === id);
}

export const calculateReduction = (p, r) => {
    let price = p - p * (r / 100);
    return (price)
}

export const setFooter = (footer) => {
    localStorage.setItem('footer',JSON.stringify(footer));
}

export const setModules = (modules) => {
    localStorage.setItem('modules',modules);
}

export const getFooter = () => {
    let items = JSON.parse(localStorage.getItem('footer')) || {};
    return items;
}

export const getModules = () => {
    let items = JSON.parse(localStorage.getItem('modules')) || {};
    return items;
}