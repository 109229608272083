import React, { useEffect } from 'react';
import './index.css';
import './mobile.css';
import TopProduct from './components/TopProduct';
import Categorie from './components/Categorie';
import ListProducts from './components/ListeProducts';
import Meilleurvente from './components/Meilleurvente';
import CovertureImg from './components/CovertureImg';
import { get } from './api/ApiCall';
import { setFooter, setModules } from './components/context';

export const App = () => {
   
    useEffect(() => {
        get('modules').then(r=>{
            setFooter(r.footer);
            setModules(r.modules);
        })
    },[]);

    return (<React.StrictMode>
        <TopProduct />
        <Categorie />
        <ListProducts />
        <Meilleurvente />
        <CovertureImg />
    </React.StrictMode>)
};

