import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import Swal from 'sweetalert2';
import { BASE_URL } from '../api/ApiCall';
import { Container, Col, Row } from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import './style/styles.scss';
import ReactImageMagnify from 'react-image-magnify';
import Magnifier from 'react-magnifier';

const Gallery = React.forwardRef((props, imageRef) => {
    const imagesRef = useRef([]);
    const [src ,setSrc]=useState(BASE_URL + '/file/' + props.images[0]);

    const settings = {
        infinite: false,
        slidesToShow: 4,
        vertical: true,
        slidesToScroll : 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: false,
                    slidesToShow: 1.2,
                    vertical: false,
                    infinite: true,
                }
            }
        ]
    };

    const openVideo = () => {
        const id = props.video.split('?v=')[1];

        Swal.fire({
            title: 'Vídeo',
            html: `<iframe width="100%" height="300" src="https://www.youtube.com/embed/${id}" frameborder="0"></iframe>`
        });
    };

    const changeImage = index => {
        imagesRef.current.forEach(image => image.classList.remove('active'));

        imagesRef.current[index].classList.add('active');

        imageRef.current.img.src = imagesRef.current[index].src;

        setSrc(imagesRef.current[index].src);
        console.log(imageRef);
        
    };
    const changeImageColor = src => {
        imageRef.current.img.src = src;
    };


    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col xs={12} md={4}>
                        <div id="gallery">
                            <div id="thumbnails">
                                <Slider   {...settings}>
                                    {props.images.map((image, index) => (
                                        <div key={index} className="thumbnail"><img ref={element => imagesRef.current[index] = element} className={index === 0 ? 'active' : ''} src={BASE_URL + '/file/' + image} alt="" onClick={() => changeImage(index)} /></div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div id="image">
                            {/* <Image style={{ width: "100%", margin: "30px 0",display:'none' }}
                                ref={imageRef} src={BASE_URL + '/file/' + props.images[0]} alt="" /> */}
                                <Magnifier ref={imageRef} src={src}  />;
                            {/* <ReactImageMagnify   {...{
                                smallImage: {
                                    isFluidWidth: true,
                                    src: src
                                },
                                largeImage: {
                                    src: src,
                                    width: 1200,
                                    height: 1800
                                }
                            }} /> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
})
export default Gallery;