import { fontWeight } from '@mui/system';
import { Container, Row, Col } from 'react-bootstrap';
import '../index.css';

function CovertureImg() {
    return (
        <Container fluid className='CovertureImg'>
            <Row className='text-center'>
                <Col md={6} className='pt-5'>
                    <div>
                        <h1 style={{ fontFamily: 'Adine', fontSize: '6em', fontWeight: 'bolder' }}>
                            Guide des sacs
                        </h1>
                        <h3>Pour Homme 2022</h3>
                        <p className='px-md-5'>Le sac à dos est le sac le plus populaire chez les hommes,
                            mais c'est peut-être aussi le plus compliquer à choisir.
                            C'est pour ça amnays a listé les différents types de sacs
                            qui sont proposés pour les hommes avec le détail pour
                            bien le porter et le choisir.</p>
                    </div>
                </Col>
                <Col md={4}>
                </Col>
            </Row>
        </Container>
    );
}

export default CovertureImg;