import '../index.css';

import { Container } from "react-bootstrap";
import { FaFacebook, FaInstagram,FaWhatsapp,FaTiktok } from 'react-icons/fa';
import { getFooter } from './context';
import { useState } from 'react';
import { useEffect } from 'react';
import { get } from '../api/ApiCall';


function SideBar() {

    const [footerLinks,setFooterLinks] = useState();
    useEffect(() => {
        get('modules').then(r=>{
            setFooterLinks(r.footer);
        })
    },[]);    let fbLink = footerLinks ? footerLinks.facebooklien : "https://www.facebook.com/amnays.com";
    let tiktokLink = footerLinks ? footerLinks.tiktoklien : "https://www.tiktok.com/amnays.com";
    let instaLink = footerLinks ? footerLinks.instagramlien : "https://www.instagram.com/amnays.com";
    let wpLink = footerLinks ? "https://wa.me/" + footerLinks.whatsappnum : "https://wa.me/0660666260";
    return (
        <Container className='side-bar postion-relative'>
            <div className='position-fixed' style={{ width: '35px', left: '2%' }}>
                <div class="widget no-box" >
                    <h6 class="widget-title mx-auto" style={{
                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed'
                    }}>Follow us on<span></span></h6>

                    <ul class="social-footer2">
                        <li class=""><a title="tiktok" target="_blank" href={tiktokLink}>  <FaTiktok style={{ fontSize: '20px', color: '#000' }} /></a></li>
                            <li class=""><a href={fbLink} target="_blank" title="Facebook"> <FaFacebook style={{ fontSize: '20px', color: '#000' }} /></a></li>
                            <li class=""><a href={wpLink} target="_blank" title="Twitter"><FaWhatsapp style={{ fontSize: '20px', color: '#000' }} /></a></li>
                            <li class=""><a title="instagram" target="_blank" href={instaLink}><FaInstagram style={{ fontSize: '20px', color: '#000' }} /></a></li> 
                    </ul>
                </div>
            </div >
        </Container>


    );
}

export default SideBar;