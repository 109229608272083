import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { FaFacebook, FaInstagram, FaWhatsapp, FaTiktok } from 'react-icons/fa';
import { get } from "../api/ApiCall";
import { getFooter } from "./context";

const Footer = () => {
    const [footerLinks,setFooterLinks] = useState();
    useEffect(() => {
        get('modules').then(r=>{
            setFooterLinks(r.footer);
        })
    },[]);
    let fbLink = footerLinks ? footerLinks.facebooklien : "https://www.facebook.com/amnays.com";
    let tiktokLink = footerLinks ? footerLinks.tiktoklien : "https://www.tiktok.com/amnays.com";
    let instaLink = footerLinks ? footerLinks.instagramlien : "https://www.instagram.com/amnays.com";
    let wpLink = footerLinks ? "https://wa.me/" + footerLinks.whatsappnum : "https://wa.me/0660666260";
    let desc = footerLinks ? footerLinks.description : 'Société AMNYAS, distributeur exclusif au Maroc de la marque internationale "BESTLIFE, vous propose une collection des sacs à dos qui répond à tous les goûts et satisfait tous vos besoins.Portez votre matériel informatique, vos livres et cahiers ou encore votre bagage de voyage, vous aurez sans doute le modèle et la taille dont vous avez besoin.Société AMNYAS, qualité et solidité au meilleur prix '
    return (<footer className="page-footer font-small blue pt-4">
        <div className="container-fluid text-center text-md-left">
            <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-3  mt-md-0 ml-md-0 mt-3">
                    <h2 className="text-uppercase">Amnays Collection</h2>
                    <p className="text-justify">{desc}</p>
                </div>

                <hr className="clearfix w-100 d-md-none pb-0" />

                <div className="col-xs-12 col-sm-6 col-md-3 mb-md-0 mb-3">
                    <h5 className="text-uppercase">Vente en détail</h5>
                    <p>+212 660-666260</p>

                </div>

                <div className="col-xs-12 col-sm-6 col-md-3 mb-md-0 mb-3">
                    <h5 className="text-uppercase">Grossiste</h5>
                    <p>+212 661-352541</p>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div class="widget no-box">
                        <h5 class="widget-title">Nous contacter<span></span></h5>

                        <ul class="social-footer2">
                            <li class=""><a title="tiktok" target="_blank" href={tiktokLink}>  <FaTiktok style={{ fontSize: '20px', color: '#fff' }} /></a></li>
                            <li class=""><a href={fbLink} target="_blank" title="Facebook"> <FaFacebook style={{ fontSize: '20px', color: '#fff' }} /></a></li>
                            <li class=""><a href={wpLink} target="_blank" title="Whatsapp"><FaWhatsapp style={{ fontSize: '20px', color: '#fff' }} /></a></li>
                            <li class=""><a title="instagram" target="_blank" href={instaLink}><FaInstagram style={{ fontSize: '20px', color: '#fff' }} /></a></li> 
                            </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-copyright text-center py-3">© 2022 Copyright:
            <a href="https://Amnays.net/"> Amnays.com</a>
        </div>

    </footer>)
}

export default Footer