import { Container, Col, Row, Card } from "react-bootstrap";
import "../index.css";
import Image4 from '../images/Image 4.png';
import Product from './Product';
import { useEffect, useState } from "react";
import { FILE_BASE_URL, get, post } from "../api/ApiCall";
import { useHistory } from "react-router";
import { useNavigate } from 'react-router-dom';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { calculateReduction } from "./context";
import { on } from "./event";
import { getQueryString } from "./Bar";

function ListProducts(props) {

  const [products, setProducts] = useState([]);
  const [paging, setPaging] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    post('Products', 'search', {
      category: getQueryString('category') || null,
      prixMin: getQueryString('prixMin') || 0,
      prixMax: getQueryString('prixMax') || 0,
      paging: {
        page: 1
      }
    }).then(r => {
      setProducts(r.data);
      setPaging(r.meta);
    })
  }, []);

  on('filterChanged', (e) => {
    post('Products', 'search', {
      category: e.detail.category,
      prixMin: e.detail.prixMin,
      prixMax: e.detail.prixMax,
      paging: {
        page: getQueryString('prixMax') || 1
      }
    }).then(r => {
      setProducts(r.data);
      setPaging(r.meta);
    })
  })

  return (
    <>
      <Container fluid className="ListProducts">
        <h1 id="prodcuts" className="text-center fancy-undeline mt-5">Nos Produits</h1>
        <Row className="mt-5" style={{ justifyContent: 'center', backgroundColor: 'white' }}>
          {products.map(p => (
            <Col className='mt-5'>
              <Link to={'/Product?p=' + p.id}>
                <Card >
                  <div className="position-relative" >
                    <div className="product-img">
                    <Card.Img variant="top" className='text-decoration-none' src={FILE_BASE_URL + p.img1} />
                    </div>
                   {checkDateWeekAgo(p.date) && <span className="divnew" >NEW</span>}
                  {p.remis ? <span className="divprix" > {`${p.remis}`}%</span> : <></>}
                  </div>
                  <Card.Body>
                    <Card.Title>{p.titre}</Card.Title>
                    <Card.Text>
                      <Row>
                        <Col className="px-0" >
                          {
                            p.remis ? <h6 style={{ color: 'red' }} className="prix text-nowrap"><s style={{ color: 'black' }}>{p.prix}</s> <s style={{ color: 'black' }}>DH</s> &nbsp;
                              {calculateReduction(p.prix, p.remis)}
                              DH</h6> : <h6 className="prix text-nowrap">
                              {calculateReduction(p.prix, p.remis)}
                              DH</h6>
                          }
                        </Col>
                        <Col >
                          {p.couleur1 !== '#f2f2f2' && <span
                            className="dot"
                            style={{ backgroundColor: p.couleur1 }}
                          ></span>}
                          {p.couleur2 !== '#f2f2f2' && <span
                            className="dot"
                            style={{ backgroundColor: p.couleur2 }}
                          ></span>}
                          {p.couleur3 !== '#f2f2f2' && <span
                            className="dot"
                            style={{ backgroundColor: p.couleur3 }}
                          ></span>}
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}

        </Row>
        <div className="text-center mt-5">
          {Array.from(Array(paging.numPages).keys()).map((x) => (
            <a href="#prodcuts" onClick={() => {
              post('Products', 'search', {
                category: getQueryString('category') || null,
                prixMin: getQueryString('prixMin') || 0,
                prixMax: getQueryString('prixMax') || 0,
                paging: {
                  page: x + 1
                }
              }).then(r => {
                setProducts(r.data);
                setPaging(r.meta);
              })
            }}>
              <span
                class="dot"
                style={{ backgroundColor: paging.page === (x + 1) ? '#ebaa34' : 'grey' }}
              ></span>
            </a>
          ))}
        </div>
      </Container>

    </>
  );
}
const checkDateWeekAgo = (date) => {

  const date1 = new Date();
  const date2 = new Date(date);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  return diffDays <=7;
}




export default ListProducts;


